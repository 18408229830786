/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from "../components/seo";

class Coupon extends React.Component {
  componentDidMount() {
    let incoming = sessionStorage.getItem('incomingsource');

    if (incoming && incoming.charAt(0) === '?') {
      incoming = incoming.substr(1);
    }

    // Add revtrax coupon
    const script = document.createElement("script");

    script.src = "https://irxcm.com/RevTrax/js/rtxiframe.jsp?parent=couponiframe&rtxuseqs=true&merchantId=30828557&programId=105206461&affiliateId=31760427&channel=brand"
    if (incoming) {
      script.src = script.src + '&' + incoming
    }

    script.async = true;

    script.addEventListener('load', () => {
      // Add revtrax render function
      const script2 = document.createElement("script");

      script2.type = 'text/javascript';
      script2.innerHTML = 'makeFrame();';

      document.body.appendChild(script2);
    });

    document.body.appendChild(script);
  }

  render() {

    return (
      <Layout>
        <SEO meta={{}} title="Coupon">
          <meta name="robots" content="noindex" />
        </SEO>
        <h1 className="sr-only">Coupon</h1>
        <main id="couponiframe" />
        <img alt="pixel" style={{'position':'absolute', visibility:'hidden'}} src="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=misc&value={%22af_revenue%22:%22[value]%22,%22af_order_id%22:%22[order_id]%22}&pixid=3c28f711-7009-49e8-9fdd-b7601574e05d" width="1" height="1" border="0" alt="" />
      </Layout>
  )
  }
}
export default Coupon;
